<template>
	<el-upload class="upload-demo" action="#" :file-list="fileList" list-type="picture-card" :limit='num' :multiple='isMult'
	 :http-request='singleUp' :auto-upload='false' :on-change='handleChange' :on-exceed="handleExceed">
		<i slot="default" class="el-icon-plus"></i>
		<!-- <el-button size="small" type="primary">点击上传</el-button> -->
		<div slot="tip" class="el-upload__tip">图片大小不超过2M<span v-if="num">,且不超过{{num}}张</span></div>
		<div slot="file" slot-scope="{file}">
			<img class="el-upload-list__item-thumbnail" :src="$comjs.imgPd(file.url)" alt="">
			<span class="el-upload-list__item-actions">
				<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
					<i class="el-icon-zoom-in"></i>
				</span>
				<span class="el-upload-list__item-delete" @click="handleDownload(file)">
					<i class="el-icon-download"></i>
				</span>
				<span class="el-upload-list__item-delete" @click="handleRemove(file)">
					<i class="el-icon-delete"></i>
				</span>
			</span>
		</div>
	</el-upload>
</template>

<script>
	export default {
		data() {
			return {
				// curFileList:[],
				limitShow: true,
				currentIndex: 0,
				isbeginUp: false
			}
		},
		computed: {
			upImgUrl() {
				return this.$comjs.piaoDuHost + '/fa/exam/goods/upload-pic'
			},
			isMult() {
				return this.num ? false : true
			}
		},
		mounted() {
			// this.lastUpNum = this.fileList.length;
		},
		props: ['fileList', 'num', 'type'],
		methods: {
			singleUp(item, fireList) {
				// console.log(item, fireList)
			},
			handleExceed() {
				this.$message({
					showClose: true,
					message: `只能上传${this.num}张图片`,
					type: 'warning'
				})
			},
			handleChange(file, fileList) {
				this.fileList.push(file)
				if (!this.isbeginUp) {
					// debugger
					this.currentIndex = fileList.indexOf(file);
					this.isbeginUp = true;
					this.$emit('loadChange', true);
					setTimeout(() => {
						this.upFile(this.currentIndex);
					},1000)
				}
				// console.log(this.fileList);
			},
			upFile(index) {
				if (index < this.fileList.length) {
					var data = {
						file: this.fileList[index].raw
					}
					let ajaxUrl = this.type == 2 ? '/fa/exam/advert/upload' : '/fa/exam/goods/upload-pic';
					this.$comjs.ajax.postAjax(ajaxUrl, data, this, (res) => {
						this.fileList[index].response = { data: { url: res.data } }
						index = index * 1 + 1;
						this.upFile(index)
					}, () => {
						this.fileList.splice(index, 1)
						this.upFile(index)
					})
				} else {
					// console.log(this.fileList)
					this.isbeginUp = false
					this.$emit('loadChange', false);
					this.$emit('change', this.fileList)
				}

			},
			handleRemove(file, fileList) {
				// console.log(fileList)
				var url = '';
				var uid = file.uid;
				if (file.response) {
					url = file.response.data.url
				} else {
					url = file.url
				}
				var data = {
					fileUrl: url
				}
				// this.$comjs.ajax.getAjax('/files/deleteTeacherCenterFile', data, this, (res) => {
					for (var i in this.fileList) {
						if (uid == this.fileList[i].uid) {
							this.fileList.splice(i, 1);
							this.lastUpNum = this.fileList.length;
							this.$emit('change', this.fileList)
							break;
						}
					}
				// })
			},
			handlePictureCardPreview(file) {
				this.$comjs.vue.$emit('bigImg', file.url)
			},
			handleDownload(file) {
				var alink = document.createElement("a");
				alink.href = this.$comjs.host + file.url;
				alink.download = file.uid + '.jpg'; //图片名/
				alink.click();
			},
			beforeAvatarUpload(file) {
				var isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('图片大小不能超过 2MB!');
				}
				// console.log('beforeAvatarUpload')
				// this.$emit('loadChange', true)
				return isLt2M;
			},
		},
	}
</script>

<style>
</style>
